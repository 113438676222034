.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

.main-logo {
  max-width: 50rem;
  text-align: center;
}

body {
  background-color: #282c34;
  color: whitesmoke;
}

section p {
  font-size: 16px;
}

.email {
  margin: 0 0 30px;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .main-logo {
    max-width: 90%;
  }

  .App-header {
    min-height: 30vh;
  }
}

.hero {
  width: 30rem;
  max-width: 100%;
  margin-bottom: 3rem;
}

.images {
  img {
    width: 30rem;
    max-width: 100%;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    margin-bottom: 0;
  }

  .images {
    img {
      margin-bottom: 0.5rem;
    }

    margin-bottom: 1.5rem;
  }
}

ul {
  padding-inline-start: 0;
  list-style: none;

  li {
    text-align: center;
    margin-bottom: 0.5rem;
  }
}
